import React from 'react'
import dataStorage from '../../dataStorage'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getConfigByWhitelabel } from '../../utils'
import { BRAND } from '../../constants'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.background.primary
    }
}))

const Success = ({ onRestart, isConnected }) => {
    const classes = useStyles()
    const [clicked, setClick] = React.useState(false)

    const onSuccess = () => {
        const link = `${dataStorage.url}?email=${dataStorage.session?.email}&accessToken=${dataStorage.session?.accessToken}&refreshToken=${dataStorage.session?.refreshToken}&tokenRefresh=${dataStorage.session?.tokenRefresh}&deviceId=${dataStorage.session?.deviceId}&baseUrl=${dataStorage.baseUrlSend}`
        window.open(dataStorage.url, `${link}`)
        dataStorage.session = {}
        getConfigByWhitelabel()
        setClick(true)
    }

    return (
        <div className={classes.container}>
            <Box sx={{ p: 3 }} />
            <img src='/login_success.svg' alt='login success' />
            <Typography variant='h5' sx={{ py: 1 }}>Login Successfully!</Typography>
            <Box sx={{ py: 6 }}>
                {
                    clicked ? <React.Fragment>
                        <Typography>You have been redirected to our trading platform.</Typography>
                        <Box sx={{ p: 1 }} />
                        <Typography variant='body2' sx={{ color: 'another.main', cursor: 'pointer' }} onClick={onRestart}>Login with another account</Typography>
                    </React.Fragment>
                        : <Button
                            disabled={!isConnected}
                            color='primary'
                            variant="contained"
                            onClick={onSuccess}>
                            {`Open ${BRAND[dataStorage.whitelabel]} App`}
                        </Button>
                }
            </Box>
        </div>
    )
}

export default Success