export const PAGE = {
    LOGIN: 'login',
    SET_PIN: 'set_pin',
    LOGIN_SUCCESS: 'login_successfully'
}
export const PAGE_SIGNUP = {
    SIGN_UP: 'signup',
    SIGN_UP_SUCCESS: 'sign_up_success'
}
export const DISPLAY_FOOTER = {
    INVISIBLE: 'invisible',
    VISIBLE: 'visible'
}

export const PIN_TYPE = {
    PIN: 'pin',
    NEW_PIN: 'new_pin',
    SET_PIN: 'set_pin',
    CONFIRM_NEW_PIN: 'confirm_new_pin'
}

export const AUTH_TYPE = {
    FORGOT_PASSWORD: 'forgot_password',
    WEB_FORGOT_PIN: 'WEB_FORGOT_PIN',
    WEB_POST_PIN: 'WEB_POST_PIN'
}

export const BRAND = {
    dev1: 'Equix',
    equix: 'Equix',
    optixtrading: 'Optix Trading',
    tradeforgood: 'Trade For Good',
    ricard: 'Shares Exchange',
    morrison: 'Morrison',
    equitystorytrader: 'Equity Story Trader'
}

export const ENV_CONFIG = {
    DEV: {
        localhost: {
            environment: 'equix',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://localhost:8080',
            favicon: '/equix/favicon.png'
        },
        dev1: {
            environment: 'equix',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://dev1.equix.app',
            favicon: '/equix/favicon.png'
        },
        equix: {
            environment: 'equix',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://dev1.equix.app',
            favicon: '/equix/favicon.png'
        },
        equitystorytrader: {
            environment: 'equity-story',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://dev1.equix.app',
            favicon: '/equitystorytrader/favicon.svg'
        },
        morrison: {
            environment: 'morrison',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://dev1.equix.app',
            favicon: '/morrison/favicon.svg'
        },
        optixtrading: {
            environment: 'optixtrading',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://dev1.equix.app',
            favicon: '/optixtrading/favicon.png'
        },
        tradeforgood: {
            environment: 'tradeforgood',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://dev1.equix.app',
            favicon: '/tradeforgood/favicon.png'
        },
        ricard: {
            environment: 'ricard',
            baseUrl: 'https://dev1-retail-api.equix.app/v1',
            url: 'http://dev1.equix.app',
            favicon: '/ricard/favicon.svg'
        },
    },
    UAT: {
        equix: {
            environment: 'equix',
            baseUrl: 'https://equix-uat-retail-api.equix.app/v1',
            url: 'http://uat.equix.app',
            favicon: '/equix/favicon.png'
        },
        equitystorytrader: {
            environment: 'equity-story',
            baseUrl: 'https://equix-uat-retail-api.equix.app/v1',
            url: 'http://equity-story-uat.equix.app',
            favicon: '/equitystorytrader/favicon.svg'
        },
        morrison: {
            environment: 'morrison',
            baseUrl: 'https://equix-uat-retail-api.equix.app/v1',
            url: 'http://morrison-uat.equix.app',
            favicon: '/morrison/favicon.svg'
        },
        optixtrading: {
            environment: 'optixtrading',
            baseUrl: 'https://equix-uat-retail-api.equix.app/v1',
            url: 'http://optixtrading-uat.equix.app',
            favicon: '/optixtrading/favicon.png'
        },
        tradeforgood: {
            environment: 'tradeforgood',
            baseUrl: 'https://equix-uat-retail-api.equix.app/v1',
            url: 'http://tradeforgood-uat.equix.app',
            favicon: '/tradeforgood/favicon.png'
        },
        ricard: {
            environment: 'ricard',
            baseUrl: 'https://equix-uat-retail-api.equix.app/v1',
            url: 'http://ricard-uat.equix.app',
            favicon: '/ricard/favicon.svg'
        },
    },
    PROD: {
        equix: {
            environment: 'equix',
            baseUrl: 'https://morrison-retail-api.equix.app/v1',
            url: 'http://web.equix.app',
            favicon: '/equix/favicon.png'
        },
        equitystorytrader: {
            environment: 'equity-story',
            baseUrl: 'https://morrison-retail-api.equix.app/v1',
            url: 'http://equitystorytrader.equix.app',
            favicon: '/equitystorytrader/favicon.svg'
        },
        morrison: {
            environment: 'morrison',
            baseUrl: 'https://morrison-retail-api.equix.app/v1',
            url: 'http://morrison.equix.app',
            favicon: '/morrison/favicon.svg'
        },
        optixtrading: {
            environment: 'optixtrading',
            baseUrl: 'https://morrison-retail-api.equix.app/v1',
            url: 'http://optixtrading.equix.app',
            favicon: '/optixtrading/favicon.png'
        },
        tradeforgood: {
            environment: 'tradeforgood',
            baseUrl: 'https://tradeforgood-retail-api.equix.app/v1',
            url: 'http://tradeforgood.equix.app',
            favicon: '/tradeforgood/favicon.png'
        },
        ricard: {
            environment: 'ricard',
            baseUrl: 'https://morrison-retail-api.equix.app/v1',
            url: 'http://ricard.equix.app',
            favicon: '/ricard/favicon.svg'
        },
    }
}