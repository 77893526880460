import React from 'react';
import { Link, Typography } from '@mui/material/';
import useStyles from './styles';
import dataStorage from '../../../dataStorage';
import MailIcon from '@mui/icons-material/Mail'

export default function Footer({ color }) {
  const classes = useStyles();

  return (
    <div className={classes.appFooter} align="center">
      <Typography
        variant="body2"
        align="center"
        component='span'
        style={{ color }}
      >
        Customer support:&nbsp;&nbsp;&nbsp;
        {<MailIcon style={{ fill: color, marginBottom: -2, fontSize: 16 }} />}&nbsp;
        <Link className='emailLink' target="_blank" href={`mailto:${dataStorage.config?.supportEmail}`}>
          {dataStorage.config?.supportEmail}
        </Link>
      </Typography>
      <div style={{ height: 8 }} />
      <Typography
        variant="body2"
        align="center"
        style={{ color }}
      >
        {dataStorage.config?.footer}
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ color }}
      >
        {dataStorage.config?.footerAddition}
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 5, cursor: 'pinter' }} onClick={() => {
        window.open('https://www.novus-fintech.com/', '_blank')
      }}>
        <Typography style={{ color: color || 'inherit' }}>Powered by</Typography>
        <img style={{ height: 20, marginLeft: 8 }} alt=''
          src={`/images/logos/${dataStorage.theme === 'dark' ? 'novus-dark' : 'novus-light'}.svg`} />
      </div>
    </div>
  );
}
