
import React from 'react'
import Paper from '@mui/material/Paper'
import { PAGE } from '../constants'
import { makeStyles } from '@mui/styles'
import Login from './Login/Login'
import SetPin from './SetPin/SetPin'
import Success from './Success/Success'
import { Typography } from '@mui/material'
// import dataStorage from '../dataStorage'
// import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => {
    return ({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        container: {
            background: theme.palette.background.primary,
            height: `calc(100% - ${theme.spacing(4)})`,
            padding: theme.spacing(2, 0, 2, 0),
            position: 'relative'
        },
        warningConnect: {
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        errorBg: {
            background: theme.palette.error.main
        }
    })
})

function Main() {
    const classes = useStyles()
    const [isConnected, setConnect] = React.useState(true)
    const [page, setPage] = React.useState(PAGE.LOGIN)
    const [error, setError] = React.useState('')
    const errorRef = React.useRef(null)

    React.useEffect(() => {
        if (error) {
            errorRef?.current?.classList?.add('show')
        } else {
            errorRef?.current?.classList?.remove('show')
        }
    }, [error])

    React.useEffect(() => {
        const online = () => {
            setError('')
            setConnect(true)
        }
        const offline = () => {
            setError('There is no Internet connection. Please check your network settings')
            setConnect(false)
        }
        window.addEventListener('online', online);
        window.addEventListener('offline', offline);
        return () => {
            window.removeEventListener('online', online);
            window.removeEventListener('offline', offline);
        }
    }, [])

    const renderPage = () => {
        switch (page) {
            case PAGE.LOGIN:
                return <Login
                    isConnected={isConnected}
                    onSetPinFirstLogin={() => setPage(PAGE.SET_PIN)}
                    onSuccess={() => setPage(PAGE.LOGIN_SUCCESS)}
                />
            case PAGE.SET_PIN:
                return <SetPin
                    isConnected={isConnected}
                    onSuccess={() => setPage(PAGE.LOGIN_SUCCESS)}
                />
            case PAGE.LOGIN_SUCCESS:
                return <Success isConnected={isConnected} onRestart={() => window.location.reload()} />
            default: return 'Page not found'
        }
    }

    const renderError = () => {
        return (
            <Typography component='span' ref={errorRef} style={{ top: 16 }} className={`${classes.errorBg} errorContainer expandable`}>{error}</Typography>
        )
    }

    return (
        <Paper elevation={0} className={classes.container}>
            {renderError()}
            {renderPage()}
        </Paper>
    );
}

export default Main;
