
import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import axios from '../../axios'
import {
    getAuthUrl,
    getDecodeUrl,
    getRefreshUrl
} from '../../api'
import CryptoJS from 'react-native-crypto-js';
import dataStorage from '../../dataStorage'
import { getErrorMessage } from '../../errors'
import PasswordField from '../PasswordField'
import TextFieldCustom from '../TextField'
import { makeStyles } from '@mui/styles'
import PinKeyboard from '../PinKeyboard'
import ResetPin from '../ResetPin/ResetPin'
import ResetPass from '../ResetPass/ResetPass'
import {
    getSessionAuth,
    getConfigByWhitelabel
} from '../../utils'
import Logo from '../Logo'
import Modal from '../Modal'

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
        width: '100%',
        height: '100%'
    },
    container: {
        background: theme.palette.background.primary,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 3, 0, 3)
    },
    errorBg: {
        background: theme.palette.error.main
    }
}))

const STEPS = {
    TYPE_EMAIL_PASSWORD: 'input_usename_password',
    TYPE_PIN: 'enter_pin',
    FORGOT_PIN: 'forgot_pin',
    RESET_PASS: 'reset_password',
}

function Login({ onSetPinFirstLogin, onSuccess, isConnected }) {
    const classes = useStyles()
    const [step, setStep] = React.useState(STEPS.TYPE_EMAIL_PASSWORD)
    const [isLoading, _setLoading] = React.useState(false)
    const [errorMessage, setError] = React.useState('')
    const [email, _setEmail] = React.useState('')
    const [password, _setPassword] = React.useState('')
    const loadingRef = React.useRef(false)
    const emailRef = React.useRef('')
    const passwordRef = React.useRef('')
    const errorRef = React.useRef(null)
    const modalRef = React.useRef(null)
    const pinRef = React.useRef(null)
    const countPinFailed = React.useRef(0)
    const networkStatus = React.useRef(isConnected)
    const pin = React.useRef('')

    const setLoading = (value) => {
        loadingRef.current = value
        _setLoading(value)
    }

    const setEmail = (value) => {
        emailRef.current = value
        _setEmail(value)
    }

    const setPassword = (value) => {
        passwordRef.current = value
        _setPassword(value)
    }

    React.useEffect(() => {
        if (errorMessage) {
            errorRef?.current?.classList?.add('show')
        } else {
            errorRef?.current?.classList?.remove('show')
        }
    }, [errorMessage])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onCheckPin = async (pinNumber) => {
        try {
            pin.current = pinNumber
            if (!isConnected) return
            setLoading(true)
            await getSessionAuth()
            const decodeData = {
                token: dataStorage.session?.refreshToken,
                pin: CryptoJS.AES.encrypt(pinNumber, dataStorage.session?.key).toString(),
                session_id: `${dataStorage.session?.sessionId}`
            }
            const decodeResponse = await axios.post(getDecodeUrl(), { data: decodeData })
            const { token } = decodeResponse.data
            dataStorage.session.tokenRefresh = token;
            const pinData = {
                refreshToken: token,
                deviceID: dataStorage.session?.deviceId
            }
            const pinResponse = await axios.post(getRefreshUrl(), { data: pinData })
            const { accessToken, baseUrl } = pinResponse.data
            dataStorage.session.accessToken = accessToken;
            // dataStorage.session.refreshToken = token
            dataStorage.baseUrl = `https://${baseUrl}/v1`
            dataStorage.baseUrlSend = `https://${baseUrl}`
            setLoading(false)
            setError('')
            onSuccess?.()
        } catch (error) {
            setLoading(false)
            const errorData = error?.response?.data
            if (errorData?.errorCode === 'TOKEN_WAS_CHANGED') {
                modalRef.current?.showModal?.('You have been signed out due to changing password!')
                return
            }
            if (errorData?.errorCode === 2089) {
                modalRef.current?.showModal?.('Your PIN has been expired. Please relogin and change your PIN')
                return
            }
            if (error.message === 'Network Error') {
                return
            }
            countPinFailed.current = countPinFailed.current + 1
            pinRef.current?.setError(`${countPinFailed.current} Failed PIN Attempt${countPinFailed.current > 1 ? 's' : ''}`, true)
            if (countPinFailed.current > 2) {
                countPinFailed.current = 0
                modalRef.current?.showModal?.('You have been automatically signed out for security reasons')
            }
        }
    }
    React.useEffect(() => {
        if (!networkStatus.current && isConnected && pin.current.length === 6) {
            onCheckPin(pin.current)
        }
        networkStatus.current = isConnected
    }, [isConnected, onCheckPin])

    React.useEffect(() => {
        const enterLogin = (event) => {
            if ('Enter' === event.code) {
                if (!(!isConnected || loadingRef.current || !emailRef.current || !passwordRef.current)) {
                    onSignin()
                }
            }
        }
        window.addEventListener('keydown', enterLogin)
        return () => {
            window.removeEventListener('keydown', enterLogin)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderStep = () => {
        switch (step) {
            case STEPS.TYPE_EMAIL_PASSWORD:
                return renderLoginForm();
            case STEPS.TYPE_PIN:
                return renderPinForm();
            default: {
                return renderLoginForm();
            }
        }
    }

    const onSignin = async () => {
        try {
            if (STEPS.TYPE_EMAIL_PASSWORD !== step) return
            if (!isConnected) return
            setLoading(true)
            dataStorage.session.email = emailRef.current
            await getSessionAuth()
            const sessionData = {
                username: emailRef.current.toLowerCase(),
                password: CryptoJS.AES.encrypt(passwordRef.current, dataStorage.session?.key).toString(),
                provider: 'paritech',
                storage_token: true,
                session_id: `${dataStorage.session?.sessionId}`
            }
            const loginResponse = await axios.post(getAuthUrl(), { data: sessionData })
            const { refreshToken, accessToken, deviceID } = loginResponse.data
            dataStorage.session.refreshToken = refreshToken
            if (accessToken) {
                dataStorage.session.deviceId = deviceID
                dataStorage.session.accessToken = accessToken
                setLoading(false)
                setError('')
                onSetPinFirstLogin()
            } else {
                dataStorage.session.refreshToken = refreshToken
                dataStorage.session.deviceId = deviceID
                setLoading(false)
                setError('')
                setStep(STEPS.TYPE_PIN)
            }
        } catch (error) {
            setLoading(false)
            const errorCode = error?.response?.data?.errorCode || error?.response?.data?.error_code
            if (+errorCode === 2059 || +errorCode === 2057) { // force change password
                dataStorage.session.accessToken = error?.response?.data?.token
                setError('')
                setStep(STEPS.RESET_PASS)
            } else setError(getErrorMessage(error))
        }
    };

    const renderLoginForm = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <TextFieldCustom name='login-widget-email' required label="Email" placeholder='Email'
                        value={email}
                        onChange={v => setEmail(v)} fullWidth />
                </Grid>
                <Box sx={{ p: 1 }} />
                <Grid item xs={12}>
                    <PasswordField name='login-widget-password' required label="Password" placeholder='Password'
                        value={password}
                        onChange={v => setPassword(v)} fullWidth />
                </Grid>
                <Box sx={{ p: 1 }} />
                <Grid item xs={12}>
                    <Button
                        color='primary'
                        variant="contained"
                        onClick={onSignin}
                        disabled={!isConnected || isLoading || !email || !password}
                        fullWidth>
                        Sign In
                        {
                            isLoading ? <CircularProgress size={24} /> : <></>
                        }
                    </Button>
                </Grid>
                <Box sx={{ p: 1 }} />
                <Grid item align='center' xs={12}>
                    <Typography variant='body2' sx={{ cursor: 'pointer' }} onClick={() => setStep(STEPS.RESET_PASS)}>Forgot password?</Typography>
                </Grid>
            </Grid >
        )
    }


    const renderPinForm = () => {
        return (
            <PinKeyboard
                ref={pinRef}
                onBack={() => setStep(STEPS.TYPE_EMAIL_PASSWORD)}
                onCheck={onCheckPin}
                onForgotPin={() => setStep(STEPS.FORGOT_PIN)}
            />
        )
    }

    const renderError = () => {
        return (
            <Typography component='span' ref={errorRef} className={`${classes.errorBg} errorContainer expandable`}>{errorMessage}</Typography>
        )
    }

    const renderHeader = () => {
        if (step === STEPS.TYPE_PIN) return <React.Fragment />
        return <Logo />
    }

    const renderTitle = () => {
        if (step !== STEPS.TYPE_EMAIL_PASSWORD || dataStorage.signInText === '') return <React.Fragment />
        return (
            <Typography variant='h5' sx={{ pt: 2, pb: 3 }}>{dataStorage.signInText}</Typography>
        )
    }

    if (step === STEPS.RESET_PASS) {
        return <ResetPass
            isConnected={isConnected}
            onSuccess={onSuccess}
            onBack={() => {
                // window.location.reload()
                setStep(STEPS.TYPE_EMAIL_PASSWORD)
            }} />
    }

    if (step === STEPS.FORGOT_PIN) {
        return <ResetPin
            email={email}
            onSuccess={onSuccess}
            onBack={() => setStep(STEPS.TYPE_PIN)} />
    }

    const onReSignIn = () => {
        dataStorage.session = {}
        getConfigByWhitelabel()
        // window.location.reload()
        setStep(STEPS.TYPE_EMAIL_PASSWORD)
    }

    return (
        <div className={classes.wrapper} onClick={() => errorMessage && setError('')}>
            {renderHeader()}
            <form className={classes.container} noValidate>
                {renderError()}
                {renderTitle()}
                {renderStep()}
            </form>
            <Modal ref={modalRef} onClose={onReSignIn} />
        </div >
    );
}

export default Login;
