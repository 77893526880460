import dataStorage from './dataStorage'
import axios from './axios'
import {
    getSessionUrl
} from './api'
import { ENV_CONFIG } from './constants'

export function capitalizeFirstLetter(string = '') {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export async function getSessionAuth() {
    try {
        // if (dataStorage.session?.sessionId) return
        const sessionId = Date.now()
        const sessionResponse = await axios.post(getSessionUrl(sessionId))
        dataStorage.session = { ...dataStorage.session, ...sessionResponse?.data?.data, sessionId }
    } catch (error) {
        console.error('get session error: ', error)
    }
}

export function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

export function setAppearance() {
    window._data = dataStorage
    const searchParams = new URLSearchParams(window.location.search)

    // set whitelabel
    const whitelabel = searchParams.get('brand')

    // set theme
    dataStorage.theme = searchParams.get('theme')

    if (!whitelabel || !dataStorage.listEnv.includes(whitelabel) || (dataStorage.theme && !['dark', 'light'].includes(dataStorage.theme))) {
        dataStorage.pageNotFound = true
        return
    } else {
        dataStorage.pageNotFound = false
    }
    dataStorage.whitelabel = whitelabel
    if (!dataStorage.theme) dataStorage.theme = 'dark'
    getConfigByWhitelabel()


    dataStorage.showLogo = searchParams.get('logo_appearance')
    const signInText = searchParams.get('sign_in_text')
    const titleText = searchParams.get('title_text')
    dataStorage.signInText = signInText === '' ? '' : (signInText || 'Sign Into Your Account')
    dataStorage.titleText = titleText || ''
    const primary = searchParams.get('background_color')
    const secondary = searchParams.get('secondary_color')
    const highlight = searchParams.get('highlight_color')
    const another = searchParams.get('login_another_color')
    const appearanceFooter = searchParams.get('footer_appearance');
    const disabled = searchParams.get('disable_color')
    const textDisabled = searchParams.get('disable_text_color')
    const error = searchParams.get('error_color')
    const color = searchParams.get('text_color')
    dataStorage.palette = {
        primary: primary ? `#${primary}` : '',
        secondary: secondary ? `#${secondary}` : '',
        highlight: highlight ? `#${highlight}` : '',
        disabled: disabled ? `#${disabled}` : '',
        textDisabled: textDisabled ? `#${textDisabled}` : '',
        error: error ? `#${error}` : '',
        color: color ? `#${color}` : '',
        another: another ? `#${another}` : '',
        appearanceFooter: appearanceFooter || ''
    }

    // set font
    let font = searchParams.get('font_name')
    dataStorage.fontSize = searchParams.get('font_size')
    if (font) {
        font = capitalizeFirstLetter(font)
        dataStorage.font = font
        const link = document.getElementById('googleFont')
        link.setAttribute('href', `https://fonts.googleapis.com/css?family=${font}`)
        document.body.style.fontFamily = `${font} !important`
    }
}

export function getConfigByWhitelabel() {
    const config = ENV_CONFIG?.[dataStorage.env]?.[dataStorage.whitelabel]
    if (config) {
        dataStorage.environment = config.environment
        dataStorage.baseUrl = config.baseUrl
        dataStorage.url = config.url
    }
    const favicon = document.getElementById('favicon')
    favicon.href = config?.favicon || '';
    axios.defaults.headers.common['environment'] = dataStorage.environment;
}