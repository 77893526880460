import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@mui/styles'
import MuiModal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        background: theme.palette.background.secondary,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        color: theme.palette.text.primary
    }
}))

const Modal = forwardRef(({ onClose }, ref) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const title = React.useRef('')

    useImperativeHandle(ref, () => ({
        showModal: showModal
    }), [])

    const showModal = (text) => {
        title.current = text
        setOpen(true)
    }
    const closeModal = () => setOpen(false)

    return (
        <MuiModal open={open} className={classes.container}>
            <div className={classes.modalContainer}>
                <Typography variant='body2' align='center' sx={{ pb: 2 }}>{title.current}</Typography>
                <Button color='primary' size='small' variant='contained' onClick={() => {
                    closeModal()
                    onClose?.()
                }}>OK</Button>
            </div>
        </MuiModal>
    );
})

export default Modal