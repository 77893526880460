import React from 'react'
import PinKeyboard from '../PinKeyboard'
import {
    PIN_TYPE,
    AUTH_TYPE
} from '../../constants'
import {
    getAuthUrl,
    getAuthPinUrl,
    getDecodeUrl
} from '../../api'
import axios from '../../axios'
import CryptoJS from 'react-native-crypto-js';
import { getErrorMessage } from '../../errors'
import dataStorage from '../../dataStorage'
import { makeStyles } from '@mui/styles'
import { getSessionAuth } from '../../utils'

const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.background.primary,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 3, 0, 3)
    }
}))

const STEPS = {
    SET_PIN: 'set_pin',
    CONFIRM_PIN: 'confirm_new_pin'
}

const SetPin = ({ onBack, onSuccess, isConnected, isForgotPass }) => {
    const classes = useStyles()
    const [step, setStep] = React.useState(STEPS.SET_PIN)
    const pinRef = React.useRef(null)
    const newPin = React.useRef([])
    const confirmPin = React.useRef([])

    const onSetPin = async () => {
        try {
            if (!isConnected) return
            const pin = newPin.current.join('')
            if (pin !== confirmPin.current.join('')) {
                setTimeout(() => {
                    pinRef.current?.setError('PIN did not match. Please try again', true)
                }, 1000)
            } else {
                pinRef.current?.setError('')
                pinRef.current?.setLoading(true)
                await getSessionAuth()
                const sessionData = {
                    username: dataStorage.session?.email.toLowerCase(),
                    password: CryptoJS.AES.encrypt(dataStorage.session?.password, dataStorage.session?.key).toString(),
                    provider: 'paritech',
                    storage_token: true,
                    session_id: `${dataStorage.session?.sessionId}`
                }
                if (isForgotPass) {
                    const loginResponse = await axios.post(getAuthUrl(), { data: sessionData })
                    const { refreshToken, accessToken, deviceID } = loginResponse.data
                    dataStorage.session.accessToken = accessToken
                    deviceID && (dataStorage.session.deviceId = deviceID)
                    dataStorage.session.refreshToken = refreshToken
                }
                const setPinData = {
                    pin: CryptoJS.AES.encrypt(pin, dataStorage.session?.key).toString(),
                    accessToken: dataStorage.session?.accessToken,
                    env: AUTH_TYPE.WEB_POST_PIN,
                    session_id: `${dataStorage.session?.sessionId}`
                }
                const changePinResponse = await axios.post(getAuthPinUrl(), { data: setPinData })
                dataStorage.session.accessToken = changePinResponse?.data?.accessToken
                dataStorage.session.refreshToken = changePinResponse?.data?.refreshToken
                const decodeData = {
                    token: dataStorage.session?.refreshToken,
                    pin: CryptoJS.AES.encrypt(pin, dataStorage.session?.key).toString(),
                    session_id: `${dataStorage.session?.sessionId}`
                }
                const decodeResponse = await axios.post(getDecodeUrl(), { data: decodeData })
                const { token } = decodeResponse.data
                dataStorage.session.tokenRefresh = token;
                dataStorage.baseUrl = `https://${changePinResponse?.data?.baseUrl}/v1`
                dataStorage.baseUrlSend = `https://${changePinResponse?.data?.baseUrl}`
                onSuccess?.()
            }
        } catch (error) {
            pinRef.current?.setError(getErrorMessage(error))
        }
    }

    const renderStep = () => {
        switch (step) {
            case STEPS.SET_PIN:
                return <PinKeyboard type={PIN_TYPE.SET_PIN}
                    onPin={p => newPin.current = p}
                    pin={newPin.current}
                    onNext={() => setStep(STEPS.CONFIRM_PIN)}
                    onBack={onBack} />
            case STEPS.CONFIRM_PIN:
                return <PinKeyboard type={PIN_TYPE.CONFIRM_NEW_PIN}
                    ref={pinRef}
                    pin={confirmPin.current}
                    onPin={p => confirmPin.current = p}
                    onNext={onSetPin}
                    onBack={() => setStep(STEPS.SET_PIN)} />
            default: return 'Page not found'
        }
    }

    return (
        <div className={classes.container}>
            {renderStep()}
        </div>
    )
}

export default SetPin