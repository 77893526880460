
import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => {
    return ({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        container: {
            background: theme.palette.background.primary,
            height: `calc(100% - ${theme.spacing(6)})`,
            padding: theme.spacing(3, 0, 3, 0)
        }
    })
})

function PageNotFound() {
    const classes = useStyles()

    return (
        <Paper elevation={0} className={classes.container}>
            <Box sx={{ p: 2 }} />
            <img src='/404.svg' alt='404' />
            <Box sx={{ p: 1 }} />
            <Typography variant='h4'>404 ERROR</Typography>
            <Box sx={{ p: 1 }} />
            <Typography variant='h5'>This page isn't available</Typography>
            <Box sx={{ p: 2 }} />
            <Typography variant='h5'>Your access may be faulty or the content cannot be found</Typography>
        </Paper>
    );
}

export default PageNotFound;
