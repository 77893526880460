
import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import axios from '../../axios'
import CryptoJS from 'react-native-crypto-js';
import dataStorage from '../../dataStorage'
import Footer from '../Component/Footer'
import { getErrorMessage } from '../../errors'
// import PasswordField from '../PasswordField'
import TextFieldCustom from '../../LoginWidget/TextField'
import { getSignUpUrl } from '../../api'
import { makeStyles } from '@mui/styles'
import Logo from '../../LoginWidget/Logo'
import { PAGE_SIGNUP, DISPLAY_FOOTER } from '../../constants'

const regex = /^([a-z0-9]+[_+.-])*[a-z0-9]+@(([a-z0-9]+-)*([a-z0-9]+)\.)+[a-z]{2,}$/i
export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
    width: '100%',
    height: '100%'
  },
  container: {
    background: theme.palette.background.primary,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3, 0, 3)
  },
  errorBg: {
    background: theme.palette.error.main
  }
}))

function Login({ onSetPinFirstLogin, onSuccess }) {
  const classes = useStyles()
  const [step, setStep] = React.useState(PAGE_SIGNUP.SIGN_UP)
  const [isLoading, _setLoading] = React.useState(false)
  const [errorMessage, setError] = React.useState('')
  const [email, _setEmail] = React.useState('')
  const [fullName, _setFullName] = React.useState('')
  const loadingRef = React.useRef(false)
  const emailRef = React.useRef('')
  const fullNameRef = React.useRef('')
  const errorRef = React.useRef(null)
  const recaptchaId = React.useRef()
  const [recaptcha, setRecaptcha] = React.useState('');
  const recaptchaRef = React.useRef('')
  const [messageFullName, setmessageFullName] = React.useState('');
  const [messageEmail, setmessageEmail] = React.useState('');
  const checkValidationFullName = React.useRef('');
  const checkValidationEmail = React.useRef('');
  const [checkFullNameBlur, setCheckFullNameBlur] = React.useState(false);
  const [checkEmailBlur, setCheckEmailBlur] = React.useState(false);
  const setLoading = (value) => {
    loadingRef.current = value
    _setLoading(value)
  }

  const setEmail = (value) => {
    emailRef.current = value
    _setEmail(value)
    onBlurEmail(value)
  }

  const setFullName = (value) => {
    fullNameRef.current = value
    _setFullName(value)
    onBlurFullName(value)
  }
  React.useEffect(() => {
    if (errorMessage) {
      errorRef?.current?.classList?.add('show')
    } else {
      errorRef?.current?.classList?.remove('show')
    }
  }, [errorMessage])

  const onCallback = (token) => {
    setRecaptcha(token)
    recaptchaRef.current = token;
  }

  const onExpiredCallback = () => {
    setRecaptcha(null);
  }
  const onErrorCallback = () => {
    setRecaptcha(null);
  }
  React.useEffect(() => {
    const enterLogin = (event) => {
      if ('Enter' === event.code) {
        if (!(isLoading || !checkValidationEmail.current || !checkValidationFullName.current || !recaptchaRef.current)) {
          onSignin()
        }
      }
    }
    window.addEventListener('keydown', enterLogin)
    return () => {
      window.removeEventListener('keydown', enterLogin)
    }


  }, [isLoading, recaptcha])


  React.useEffect(() => {
    recaptchaId.current = window.grecaptcha?.render('recaptcha', {
      sitekey: '6LdaBzEaAAAAAMP16MYvQI3mA4ReJv1k167rHSeS',
      callback: onCallback,
      theme: dataStorage?.theme || 'dark',
      lang: 'en',
      'expired-callback': onErrorCallback,
      'error-callback': onExpiredCallback
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderStep = () => {
    switch (step) {
      case PAGE_SIGNUP.SIGN_UP:
        return renderSignUpForm();
      case PAGE_SIGNUP.SIGN_UP_SUCCESS:
        return renderSignUpSuccess();
      default: {
        return renderSignUpForm();
      }
    }
  }
  const onBlurFullName = (e, disableSetBlur = false) => {
    const valueField = typeof e == 'string' ? e : e?.target?.value;
    disableSetBlur && setCheckFullNameBlur(true)
    if (!valueField) {
      checkValidationEmail.current = 0
      return setmessageFullName('Full Name is required');
    }
    if (valueField.length < 3 || valueField.length > 255) {
      checkValidationEmail.current = 0
      return setmessageFullName('Full Name must be 3 to 255 characters');
    }
    checkValidationEmail.current = 1
    setmessageFullName('')
  }

  const onBlurEmail = (e, disableSetBlur = false) => {
    const valueField = typeof e == 'string' ? e : e?.target?.value;
    disableSetBlur && setCheckEmailBlur(true)
    if (!valueField) {
      checkValidationFullName.current = 0
      return setmessageEmail('Email is required');
    }
    if (!regex.test(valueField)) {
      checkValidationFullName.current = 0
      return setmessageEmail('Email is invalid');
    }
    checkValidationFullName.current = 1
    setmessageEmail('')
  }
  const onSignin = async () => {
    setLoading(true)
    const query = {
      user_login_id: emailRef.current,
      full_name: fullNameRef.current,
      recaptcha
    }
    axios.post(getSignUpUrl(), query).then((res) => {
      setLoading(false)
      setStep(PAGE_SIGNUP.SIGN_UP_SUCCESS)
    }).catch(error => {
      setLoading(false)
      const errorCode = error?.response?.data?.errorCode || error?.response?.data?.error_code;
      if (+errorCode === 8000) {
        window.grecaptcha.reset(recaptchaId.current)
        setRecaptcha('')
        recaptchaRef.current = ''
      }
      if (+errorCode === 2059 || +errorCode === 2057) { // force change password
        dataStorage.session.accessToken = error?.response?.data?.token
        setError('')
      } else setError(getErrorMessage(error))
    })
    // console.log(query)

  };
  const renderSignUpSuccess = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ pt: 2, pb: 3, fontWeight: 600 }}>{'Thank you for signing up'}</Typography>
        </Grid>
        <Box sx={{ p: 1 }} />
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom component="div" sx={{ pt: 2, pb: 3 }}>{'Please check your email inbox for information to preview our app'}</Typography>
        </Grid>
      </Grid>
    )
  }
  const renderFooter = () => {
    if (!dataStorage.palette.appearanceFooter) return null;
    return <React.Fragment>
      {dataStorage.palette.appearanceFooter === DISPLAY_FOOTER.VISIBLE ? <Footer /> : null}
    </React.Fragment>
  }
  const renderSignUpForm = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <TextFieldCustom name='full_name' required label="Full Name" placeholder='Full Name'
              value={email}
              error={checkFullNameBlur ? !!messageFullName : false}
              onChange={v => setFullName(v)}
              onBlur={(e) => onBlurFullName(e, true)}
              helperText={checkFullNameBlur ? messageFullName : ''}
              fullWidth disableTrim={true} />
          </Grid>
          <Box sx={{ p: 1 }} />
          <Grid item xs={12}>
            <TextFieldCustom name='email' required label="Email" placeholder='Email'
              value={email}
              error={checkEmailBlur ? !!messageEmail : false}
              onBlur={(e) => onBlurEmail(e, true)}
              helperText={checkEmailBlur ? messageEmail : ''}
              onChange={v => setEmail(v)} fullWidth />
          </Grid>
          <Box sx={{ p: 1 }} />
          <Grid item xs={12}>
            <div id='recaptcha' />
          </Grid>
          <Box sx={{ p: 1 }} />
          <Grid item xs={12}>
            <Button
              color='primary'
              variant="contained"
              onClick={onSignin}
              disabled={isLoading || (Boolean(messageFullName) || !fullName) || (Boolean(messageEmail) || !email) || !recaptcha}
              fullWidth
              style={{ textTransform: 'uppercase' }}
            >
              confirm
              {
                isLoading ? <CircularProgress size={24} /> : <></>
              }
            </Button>
          </Grid>
          {/* <Box sx={{ p: 1 }} />
        <Grid item align='center' xs={12}>
          <Typography variant='body2' sx={{ cursor: 'pointer' }} onClick={() => setStep(STEPS.RESET_PASS)}>Forgot password?</Typography>
        </Grid> */}
        </Grid >
        {renderFooter()}
      </>
    )
  }

  const renderError = () => {
    return (
      <Typography component='span' ref={errorRef} className={`${classes.errorBg} errorContainer expandable`}>{errorMessage}</Typography>
    )
  }
  const renderHeader = () => {
    // if (step === PAGE_SIGNUP.TYPE_PIN) return <React.Fragment />
    return <Logo />
  }

  const renderTitle = () => {
    if (step !== PAGE_SIGNUP.SIGN_UP) return <React.Fragment />
    return (
      <Typography variant='h5' sx={{ pt: 2, pb: 3, fontWeight: 600 }}>{dataStorage.titleText || 'SIGN UP'}</Typography>
    )
  }

  return (
    <div className={classes.wrapper} onClick={() => errorMessage && setError('')}>
      {renderHeader()}
      <form className={classes.container} noValidate>
        {renderError()}
        {renderTitle()}
        {renderStep()}
      </form>
      {/* <Modal ref={modalRef} onClose={onReSignIn} /> */}
    </div >
  );
}

export default Login;
