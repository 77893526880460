/* eslint-disable no-constant-condition */
import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MuiTextField from '@mui/material/TextField';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function PasswordField(props) {
  const { errorText, onChange, ...rest } = props;
  const [visibility, setVisibility] = useState(false)
  const [value, setValue] = useState(props.value || '')
  const isSmallScreen = useMediaQuery('(max-width:376px)');

  const handleClickShowPassword = () => {
    setVisibility(value => !value);
  }

  const onChangeText = (e) => {
    const value = e.target.value || ''
    onChange?.(value.trim())
    setValue(value.trim());
  }

  const renderIcon = () => {
    return value ? {
      endAdornment:
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {visibility ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
    } : null
  }

  const size = isSmallScreen ? 'small' : 'medium'
  return (
    <MuiTextField
      size={size}
      type={visibility ? 'text' : 'password'}
      fullWidth
      {...rest}
      onChange={onChangeText}
      inputProps={{
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off'
        }
      }}
      InputProps={renderIcon()}
    />
  )
}
