import React, { forwardRef, useImperativeHandle } from 'react';
import BackspaceIcon from '@mui/icons-material/BackspaceOutlined'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { PIN_TYPE } from '../constants';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: theme.spacing(2)
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%'
    },
    keyboardButton: {
        userSelect: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.typography.fontSize * 2.5,
        borderRadius: theme.spacing(0.5),
        fontSize: theme.typography.h5.fontSize,
        backgroundColor: theme.palette.background.secondary,
        margin: theme.spacing(0.5),
        width: `calc(100%/3 - ${theme.spacing(1)})`,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary.main
        }
    },
    hiddenButton: {
        backgroundColor: 'transparent',
        visibility: 'hidden',
        pointerEvents: 'none'
    },
    deleteButton: {
        backgroundColor: 'transparent'
    },
    pinInput: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 0, 1, 0),
        height: 40
    },
    pinSymbol: {
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(0, 1, 0, 1)
    },
    pinSymbolActive: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.h6.fontSize
    },
    pinSymbolNumber: {
        fontSize: theme.typography.h5.fontSize
    },
    textButton: {
        width: 'fit-content'
    },
    header: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0, 2, 0),
        '& button': {
            '&:hover': {
                background: 'transparent'
            }
        }
    }
}))

const LIST_KEYBOARD = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0, 'delete']
const PIN_PATTERM = [1, 2, 3, 4, 5, 6]

const PinKeyboard = forwardRef(({ type, pin = [], onPin, onCheck, onForgotPin, onBack, onNext }, ref) => {
    const classes = useStyles()
    const [number, setNumber] = React.useState(pin)
    const [errorMessage, setError] = React.useState('')
    const [isLoading, setLoading] = React.useState('')
    const pinRef = React.useRef([])
    const [showNumber, setShowNumber] = React.useState(false)
    const timeoutId = React.useRef(null)

    useImperativeHandle(ref, () => ({
        setError: setErrorPin,
        setLoading: setLoading
    }))

    const clearPin = () => {
        pinRef.current = []
        onPin?.([])
        setNumber([])
    }

    const setErrorPin = (msg, isClear) => {
        setLoading(false)
        setError(msg)
        if (isClear) clearPin()
    }

    const handleKeyBoard = (item) => {
        timeoutId.current && clearTimeout(timeoutId.current)
        if (item === 'delete') {
            pinRef.current.splice(-1, 1)
            showNumber && setShowNumber(false)
            onPin?.([...pinRef.current])
        } else if (pinRef.current.length < 6) {
            pinRef.current.push(item)
            setShowNumber(true)
            timeoutId.current = setTimeout(() => {
                setShowNumber(false)
            }, 2000)
            if (pinRef.current.length === 6) {
                onPin?.([...pinRef.current])
                if (onCheck) {
                    onCheck(pinRef.current.join(''))
                } else if (onNext) {
                    setShowNumber(false)
                    onNext()
                }
            } else {
                onPin?.([...pinRef.current])
            }
        }
        setNumber([...pinRef.current])
    }

    React.useEffect(() => {
        const handleKeyboardPress = (e) => {
            if (e.key === 'Backspace') {
                handleKeyBoard('delete');
            } else if (/^\d+$/.test(e.key)) {
                handleKeyBoard(Number(e.key));
            }
        }
        document.addEventListener('keydown', handleKeyboardPress)
        return () => {
            document.removeEventListener('keydown', handleKeyboardPress)
        }
    }, [type]) // eslint-disable-line

    React.useEffect(() => {
        setNumber(pin)
        pinRef.current = pin
    }, [type]) // eslint-disable-line

    const mapKeyBoard = () => {
        const keyboard = LIST_KEYBOARD.map((item, index) => {
            return (
                <div
                    className={classnames(classes.keyboardButton, {
                        [classes.hiddenButton]: item === '',
                        [classes.deleteButton]: item === 'delete'
                    })}
                    onClick={() => handleKeyBoard(item)}
                    key={index}>
                    {item === 'delete' ? <BackspaceIcon /> : item}
                </div>
            );
        })
        return keyboard;
    }

    const renderInput = () => {
        return (
            <div className={classes.pinInput}>
                {
                    PIN_PATTERM.map((e, i) => {
                        const isHaveValue = typeof number[i] === 'number'
                        const isLastItem = i === number.length - 1
                        return (
                            <div key={`pin_number_${i}`}
                                className={classnames(classes.pinSymbol, {
                                    [classes.pinSymbolActive]: isHaveValue,
                                    [classes.pinSymbolNumber]: isLastItem && showNumber,
                                })}>
                                {isLastItem && showNumber ? number[i] : '✲'}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderHeader = () => {
        switch (type) {
            case PIN_TYPE.SET_PIN:
                return <div className={classes.header}>
                    <Button sx={{ pointerEvents: 'none' }}></Button>
                    <Typography variant='h6'>Set Your PIN</Typography>
                    <Button disabled={number.length < 6} onClick={onNext}>Next</Button>
                </div>
            case PIN_TYPE.NEW_PIN:
                return <div className={classes.header}>
                    <Button variant='text' onClick={() => {
                        setError('')
                        onBack?.()
                    }}>Back</Button>
                    <Typography variant='h6'>Enter Your New PIN</Typography>
                    <Button variant='text' disabled={number.length < 6} onClick={onNext}>Next</Button>
                </div>
            case PIN_TYPE.CONFIRM_NEW_PIN:
                return <div className={classes.header}>
                    <Button variant='text' onClick={() => {
                        setError('')
                        onBack?.()
                    }}>Back</Button>
                    <Typography variant='h6'>Confirm Your New PIN</Typography>
                    <Button variant='text' disabled={isLoading || number.length < 6} onClick={onNext}>
                        Done
                        {
                            isLoading ? <CircularProgress size={24} /> : <></>
                        }
                    </Button>
                </div>
            default: return <div className={classes.header}>
                <Button variant='text' onClick={() => {
                    setError('')
                    onBack?.()
                }}>Back</Button>
                <Typography variant='h6' sx={{ width: '100%' }}>Enter Your PIN</Typography>
                <Button sx={{ pointerEvents: 'none' }}></Button>
            </div>
        }
    }

    const renderMore = () => {
        if (type) {
            if (type === PIN_TYPE.SET_PIN) {
                return <Typography align='left' sx={{ width: '100%', py: 2 }}>A 6-Digit PIN is required. Security PIN will help you login quickly and protect your account.
                </Typography>
            }
            return <Box sx={{ p: 2 }}></Box>
        }
        return <Typography variant='body2' className={classes.textButton} sx={{ mb: 2, mt: 0.5, cursor: 'pointer' }} onClick={onForgotPin}>Forgot PIN?</Typography>
    }

    const renderError = () => {
        return <FormHelperText error sx={{ pt: 1 }}>{errorMessage}</FormHelperText>
    }

    return (
        <div className={classes.wrapper}>
            {renderHeader()}
            {renderInput()}
            {renderError()}
            {renderMore()}
            <div className={classes.container}>
                {mapKeyBoard()}
            </div>
        </div>
    )
})

export default PinKeyboard

