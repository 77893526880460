import React from 'react'
import Typography from '@mui/material/Typography'
// import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CheckIcon from '@mui/icons-material/Check'
import PinKeyboard from '../PinKeyboard'
import {
    AUTH_TYPE,
    PIN_TYPE
} from '../../constants'
import PasswordField from '../PasswordField'
import { makeStyles } from '@mui/styles'
import {
    getAuthUrl,
    getAuthPinUrl,
    getDecodeUrl
} from '../../api'
import axios from '../../axios'
import CryptoJS from 'react-native-crypto-js';
import { getErrorMessage } from '../../errors'
import { getSessionAuth } from '../../utils'
import dataStorage from '../../dataStorage'
import ResponsiveButton from '../ResponsiveButton'

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
        width: '100%',
        height: '100%',
        paddingBottom: theme.spacing(2)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& button': {
            flex: 1,
            background: theme.palette.background.secondary,
            borderColor: theme.palette.textFieldBorder.main,
            '&:hover': {
                borderColor: theme.palette.textFieldBorder.hover,
                background: theme.palette.primary.main,
            }
        },
        '& button ~ button': {
            background: theme.palette.background.primary,
            marginLeft: 12,
            color: theme.palette.text.primary
        }
    },
    container: {
        background: theme.palette.background.primary,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 3, 0, 3)
    },
    errorBg: {
        background: theme.palette.error.main
    }
}))

const STEPS = {
    TYPE_CURRENT_PASS: 'input_current_password',
    ENTER_NEW_PIN: 'enter_new_pin',
    CONFIRM_PIN: 'confirm_new_pin'
}

const ResetPin = ({ email, onBack, onSuccess }) => {
    const classes = useStyles()
    const [step, setStep] = React.useState(STEPS.TYPE_CURRENT_PASS)
    const [isLoading, setLoading] = React.useState(false)
    const [errorMessage, setError] = React.useState('')
    const [password, setPassword] = React.useState('')
    const errorRef = React.useRef(null)
    const pinRef = React.useRef(null)
    const tokenResetPin = React.useRef('')
    const newPin = React.useRef([])
    const confirmPin = React.useRef([])

    React.useEffect(() => {
        if (errorMessage) {
            errorRef?.current?.classList?.add('show')
        } else {
            errorRef?.current?.classList?.remove('show')
        }
    }, [errorMessage])

    const renderHeader = () => {
        if (step !== STEPS.TYPE_CURRENT_PASS) return <React.Fragment />
        return <Typography variant='h6' sx={{ pb: 3, pt: 1 }}>Reset Your PIN</Typography>
    }

    const renderTitle = () => {
        if (step !== STEPS.TYPE_CURRENT_PASS) return <React.Fragment />
        return <Typography sx={{ pb: 1 }}>Please enter your current password</Typography>
    }

    const onCheckCurrentPass = async () => {
        try {
            setLoading(true)
            await getSessionAuth()
            const sessionData = {
                username: email.trim().toLowerCase(),
                password: CryptoJS.AES.encrypt(password, dataStorage.session?.key).toString(),
                provider: 'paritech',
                storage_token: false,
                session_id: `${dataStorage.session?.sessionId}`
            }
            const loginResponse = await axios.post(getAuthUrl(), { data: sessionData })
            const { refreshToken, accessToken, deviceID } = loginResponse.data
            refreshToken && (dataStorage.session.refreshToken = refreshToken)
            dataStorage.session.deviceId = deviceID
            tokenResetPin.current = accessToken
            setLoading(false)
            setError('')
            setStep(STEPS.ENTER_NEW_PIN)
        } catch (error) {
            setLoading(false)
            setError(getErrorMessage(error))
        }
    }

    const renderCurrentPassForm = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <PasswordField required label="Password" placeholder='Password'
                        onChange={v => setPassword(v)} fullWidth />
                </Grid>
                <Box sx={{ p: 1 }} />
                <Grid item container className={classes.buttonContainer} xs={12}>
                    <ResponsiveButton
                        onClick={onBack}
                        variant='outlined'>Back</ResponsiveButton>
                    <ResponsiveButton
                        onClick={onCheckCurrentPass}
                        disabled={isLoading || !password}
                        variant='outlined'>
                        <CheckIcon />
                        OK
                        {
                            isLoading ? <CircularProgress size={24} /> : <></>
                        }
                    </ResponsiveButton>
                </Grid>
            </Grid>
        )
    }

    const onResetPin = async () => {
        try {
            const pin = newPin.current.join('')
            if (pin !== confirmPin.current.join('')) {
                setTimeout(() => {
                    pinRef.current?.setError('PIN did not match. Please try again', true)
                }, 1000)
            } else {
                pinRef.current?.setLoading(true)
                pinRef.current?.setError('')
                await getSessionAuth()
                const changePinData = {
                    pin: CryptoJS.AES.encrypt(pin, dataStorage.session?.key).toString(),
                    accessToken: tokenResetPin.current,
                    env: AUTH_TYPE.WEB_FORGOT_PIN,
                    session_id: `${dataStorage.session?.sessionId}`
                }
                const changePinResponse = await axios.post(getAuthPinUrl(), { data: changePinData })
                dataStorage.session.accessToken = changePinResponse?.data?.accessToken
                dataStorage.session.refreshToken = changePinResponse?.data?.refreshToken
                dataStorage.baseUrl = `https://${changePinResponse?.data?.baseUrl}/v1`
                dataStorage.baseUrlSend = `https://${changePinResponse?.data?.baseUrl}`
                const decodeData = {
                    token: dataStorage.session?.refreshToken,
                    pin: CryptoJS.AES.encrypt(pin, dataStorage.session?.key).toString(),
                    session_id: `${dataStorage.session?.sessionId}`
                }
                const decodeResponse = await axios.post(getDecodeUrl(), { data: decodeData })
                const { token } = decodeResponse.data
                dataStorage.session.tokenRefresh = token;
                onSuccess?.()
            }
        } catch (error) {
            setLoading(false)
            pinRef.current?.setError(getErrorMessage(error))
        }
    }

    const renderStep = () => {
        switch (step) {
            case STEPS.TYPE_CURRENT_PASS:
                return renderCurrentPassForm()
            case STEPS.ENTER_NEW_PIN:
                return <PinKeyboard type={PIN_TYPE.NEW_PIN}
                    onPin={p => newPin.current = p}
                    pin={newPin.current}
                    onNext={() => setStep(STEPS.CONFIRM_PIN)}
                    onBack={onBack} />
            case STEPS.CONFIRM_PIN:
                return <PinKeyboard type={PIN_TYPE.CONFIRM_NEW_PIN}
                    ref={pinRef}
                    pin={confirmPin.current}
                    onPin={p => confirmPin.current = p}
                    onNext={onResetPin}
                    onBack={() => setStep(STEPS.ENTER_NEW_PIN)} />
            default: return 'Page not found'
        }
    }

    const renderError = () => {
        return (
            <Typography component='span' ref={errorRef} className={`${classes.errorBg} errorContainer expandable`}>{errorMessage}</Typography>
        )
    }

    return (
        <div className={classes.wrapper} onClick={() => errorMessage && setError('')}>
            {renderHeader()}
            <div className={classes.container}>
                {renderError()}
                {renderTitle()}
                {renderStep()}
            </div>
        </div>
    )
}

export default ResetPin