import React from 'react'
import dataStorage from "../dataStorage"
import { makeStyles } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2, 0, 2, 0),
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    logoContainer: {
        width: '100%',
        height: theme.typography.fontSize * 4,
        display: 'flex',
        justifyContent: 'center',
        '& img': {
            maxHeight: theme.typography.fontSize * 4,
            maxWidth: '80%'
        }
    }
}))

const Logo = () => {
    const classes = useStyles()
    const isSmallScreen = useMediaQuery('(max-width:376px)');

    if (dataStorage.showLogo === 'invisible') return <React.Fragment />
    return (
        <div className={classes.container}>
            <div className={classes.logoContainer}>
                <img style={isSmallScreen ? { maxWidth: 'calc(100% - 32px)' } : {}} src={`/${dataStorage.whitelabel}/${dataStorage.theme === 'light' ? 'logoLight' : 'logoDark'}.svg`} alt="logo" />
            </div>
        </div>
    )
}

export default Logo