// First we need to import axios.js
import axios from 'axios';
import dataStorage from './dataStorage';
// Next we make an 'instance' of it
const instance = axios.create({
    // .. where we make our configurations
    // baseURL: 'https://api.example.com'
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['environment'] = dataStorage.environment;

// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

export default instance;