import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery';

function ResponsiveButton({ children, ...props }) {
    const isSmallScreen = useMediaQuery('(max-width:376px)');

    const size = isSmallScreen ? 'medium' : 'large'
    return (
        <Button {...props} size={size}>
            {children}
        </Button>
    );
}

export default ResponsiveButton