import dataStorage from './dataStorage'
import axios from './axios'

export function getSessionUrl(id) {
    return `${dataStorage.baseUrl}/auth/session?session_id=${id}`
}

export function getAuthUrl() {
    return `${dataStorage.baseUrl}/auth`
}

export function getAuthPinUrl() {
    return `${dataStorage.baseUrl}/auth/pin`
}
export function getSignUpUrl() {
    return `${dataStorage.baseUrl}/user/sign-up`;
}

export function getVerifyEmailUrl() {
    return `${dataStorage.baseUrl}/auth/verify-username`
}

export function getCodeVerifyEmailUrl() {
    return `${dataStorage.baseUrl}/auth/send-verify-username`
}

export function getRefreshUrl() {
    return `${dataStorage.baseUrl}/auth/refresh`
}

export function getDecodeUrl() {
    return `${dataStorage.baseUrl}/auth/decode`
}

export function getCreatePasswordUrl(env) {
    return `${dataStorage.baseUrl}/auth/create-password`
}

export async function getSessionAuth() {
    try {
        if (dataStorage.session?.sessionId) return
        const sessionId = Date.now()
        const sessionResponse = await axios.post(getSessionUrl(sessionId))
        dataStorage.session = { ...dataStorage.session, ...sessionResponse?.data?.data, sessionId }
    } catch (error) {
        console.error('get session error: ', error)
    }
}