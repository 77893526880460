import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextFieldCustom from './TextField'
import { makeStyles } from '@mui/styles'
import { AUTH_TYPE } from '../constants'
import {
    getCodeVerifyEmailUrl
} from '../api'
import axios from '../axios'
import { getErrorMessage } from '../errors'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiTextField-root': {
            flex: 1,
            marginRight: theme.spacing(1.5)
        }
    }
}))

const SendCodeButton = ({ onChange, setError }) => {
    const classes = useStyles()
    const [isValid, setValid] = React.useState(false)
    const [count, setCount] = React.useState(0)
    const [email, setEmail] = React.useState('')
    const timeoutId = React.useRef(null)

    const startCount = () => {
        let time = 60
        setCount(60)
        timeoutId.current && clearInterval(timeoutId.current)
        timeoutId.current = setInterval(() => {
            if (time === 1) {
                clearInterval(timeoutId.current)
            }
            time--
            setCount(time => time - 1)
        }, 1000)
    }

    const onSendCode = async () => {
        try {
            if (count || !email) return;
            const verifyData = {
                user_login_id: email.trim(),
                type: AUTH_TYPE.FORGOT_PASSWORD
            }
            await axios.post(getCodeVerifyEmailUrl(), { data: verifyData })
            startCount()
        } catch (error) {
            setCount(0)
            setError(getErrorMessage(error))
        }
    }

    return (
        <Grid item xs={12} container className={classes.container}>
            <TextFieldCustom required label="Email" placeholder='Email'
                onChange={v => {
                    if (/^([a-z0-9]+[_+.-])*[a-z0-9]+@(([a-z0-9]+-)*([a-z0-9]+)\.)+[a-z]{2,}$/i.test(v)) {
                        if (!isValid) setValid(true)
                    } else {
                        if (isValid) setValid(false)
                    }
                    setEmail(v)
                    onChange?.(v)
                }} />
            <Button
                sx={{ height: '100%' }}
                color='primary'
                onClick={onSendCode}
                disabled={!isValid || !email || count}
                variant='contained'>{count || 'Send code'}</Button>
        </Grid>
    )
}

export default SendCodeButton