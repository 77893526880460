import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  setAppearance
} from './utils'
import dataStorage from './dataStorage'

setAppearance()

const initialWebsite = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// console.log(dataStorage.whitelabel)
fetch(`${window.location.origin}/web.json?${+new Date()}`)
  .then(response => response.json())
  .then((configWeb) => {
    dataStorage.config = configWeb && configWeb[dataStorage.whitelabel];
    initialWebsite()
  })
  .catch(error => {
    console.error('get config error', error)
    initialWebsite()
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
