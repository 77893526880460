
import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
// import Button from '@mui/material/Button'
import axios from '../../axios'
import {
    getVerifyEmailUrl,
    getCreatePasswordUrl
} from '../../api'
import { getErrorMessage } from '../../errors'
import PasswordField from '../PasswordField'
import TextFieldCustom from '../TextField'
import SendCode from '../SendCode'
import { makeStyles } from '@mui/styles'
import SetPin from '../SetPin/SetPin'
import { AUTH_TYPE } from '../../constants'
import Logo from '../Logo'
import ResponsiveButton from '../ResponsiveButton'
import dataStorage from '../../dataStorage'

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
        width: '100%',
        height: '100%',
        paddingBottom: theme.spacing(2)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& button': {
            flex: 1,
            borderColor: theme.palette.textFieldBorder.main,
            '&:hover': {
                borderColor: theme.palette.textFieldBorder.hover
            }
        },
        '& button ~ button': {
            marginLeft: theme.spacing(1.5),
            background: theme.palette.background.secondary,
            color: theme.palette.text.primary
        }
    },
    container: {
        background: theme.palette.background.primary,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 3, 2, 3)
    },
    errorBg: {
        background: theme.palette.error.main
    }
}))

const STEPS = {
    VERIFY_EMAIL: 'verify_email',
    NEW_PASSWORD: 'confirm_new_password',
    FORCE_SET_PIN: 'force_set_pin'
}

function ResetPass({ onBack, onSuccess, isConnected }) {
    const classes = useStyles()
    const isCreatePass = React.useRef(!!dataStorage.session?.accessToken)
    const [step, setStep] = React.useState(isCreatePass.current ? STEPS.NEW_PASSWORD : STEPS.VERIFY_EMAIL)
    const [isLoading, setLoading] = React.useState(false)
    const [errorMessage, setError] = React.useState('')
    const [email, setEmail] = React.useState(isCreatePass.current ? dataStorage.session?.email : '')
    const [code, setCode] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const errorRef = React.useRef(null)
    const tokenChangePass = React.useRef(dataStorage.session?.accessToken)

    React.useEffect(() => {
        if (errorMessage) {
            errorRef?.current?.classList?.add('show')
        } else {
            errorRef?.current?.classList?.remove('show')
        }
    }, [errorMessage])

    const renderStep = () => {
        switch (step) {
            case STEPS.VERIFY_EMAIL:
                return renderConfirmEmailForm();
            case STEPS.NEW_PASSWORD:
                return renderConfirmNewPasswordForm();
            default: {
                return renderConfirmEmailForm();
            }
        }
    }

    const onCheckCode = async () => {
        try {
            if (!isConnected) return
            if (code.length !== 6 || !(+code)) {
                setError('Invalid digits code. Try again')
            } else {
                setLoading(true)
                const verifyEmail = {
                    user_login_id: email.trim(),
                    verify_code: code,
                    type: AUTH_TYPE.FORGOT_PASSWORD
                }
                const verifyEmailResponse = await axios.post(getVerifyEmailUrl(), { data: verifyEmail })
                tokenChangePass.current = verifyEmailResponse?.data?.token
                setLoading(false)
                setError('')
                setStep(STEPS.NEW_PASSWORD)
            }
        } catch (error) {
            setLoading(false)
            setError(getErrorMessage(error))
        }
    };

    const renderConfirmEmailForm = () => {
        return (
            <Grid container>
                <SendCode
                    setError={setError}
                    onChange={v => setEmail(v)} />
                <Box sx={{ p: 1 }} />
                <Grid item xs={12}>
                    <TextFieldCustom required label='Enter 6-Digit Code' placeholder='Enter 6-Digit Code'
                        onChange={v => setCode(v)} fullWidth />
                </Grid>
                <Box sx={{ p: 1 }} />
                <Grid item container className={classes.buttonContainer} xs={12}>
                    <ResponsiveButton
                        onClick={onCheckCode}
                        disabled={!isConnected || isLoading || !email || !code}
                        variant='contained'>
                        Confirm
                        {
                            isLoading ? <CircularProgress size={24} /> : <></>
                        }
                    </ResponsiveButton>
                    <ResponsiveButton
                        onClick={() => {
                            dataStorage.session.accessToken = null
                            onBack?.()
                        }}
                        variant='contained'>Cancel</ResponsiveButton>
                </Grid>
            </Grid>
        )
    }

    const onChangePassword = async () => {
        try {
            if (!isConnected) return
            if (password !== confirmPassword) {
                setError("Password and repeat password don't match.")
            } else {
                setLoading(true)
                const dataChangePass = {
                    token: tokenChangePass.current,
                    user_login_id: email,
                    password: password
                }
                await axios.post(getCreatePasswordUrl(), { data: dataChangePass })
                dataStorage.session.email = email
                dataStorage.session.password = password
                setLoading(false)
                setError('')
                setStep(STEPS.FORCE_SET_PIN)
            }
        } catch (error) {
            setLoading(false)
            setError(getErrorMessage(error))
        }
    }

    const renderConfirmNewPasswordForm = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <PasswordField required label='Password' placeholder='Password'
                        onChange={v => setPassword(v)} fullWidth />
                </Grid>
                <Box sx={{ p: 1 }} />
                <Grid item xs={12}>
                    <PasswordField required label='Confirm password' placeholder='Confirm password'
                        onChange={v => setConfirmPassword(v)} fullWidth />
                </Grid>
                <Box sx={{ p: 1 }} />
                <Grid item container className={classes.buttonContainer} xs={12}>
                    <ResponsiveButton
                        onClick={onChangePassword}
                        disabled={!isConnected || isLoading || !password || !confirmPassword}
                        variant='contained'>
                        {isCreatePass.current ? 'Create Password' : 'Change Password'}
                        {
                            isLoading ? <CircularProgress size={24} /> : <></>
                        }
                    </ResponsiveButton>
                    <ResponsiveButton
                        onClick={() => {
                            dataStorage.session.accessToken = null
                            onBack?.()
                        }}
                        variant='contained'>Cancel</ResponsiveButton>
                </Grid>
            </Grid>
        )
    }

    const renderError = () => {
        return (
            <Typography component='span' ref={errorRef} className={`${classes.errorBg} errorContainer expandable`}>{errorMessage}</Typography>
        )
    }

    const renderHeader = () => {
        return <Logo />
    }

    const renderTitle = () => {
        switch (step) {
            case STEPS.VERIFY_EMAIL:
                return <Typography align='left' sx={{ width: '100%', pt: 1, pb: 2, px: 2 }}>Please enter your email below and click "Send code" to reset your password</Typography>
            case STEPS.NEW_PASSWORD:
                return <Typography align={isCreatePass.current ? 'center' : 'left'} sx={{ width: '100%', pt: 1, pb: 2, px: 2 }}>{isCreatePass.current ? 'Create a new password.' : 'Please choose a new password to finish resetting password.'}</Typography>
            default: return ''
        }
    }

    if (step === STEPS.FORCE_SET_PIN) {
        return <SetPin
            isForgotPass={true}
            isConnected={isConnected}
            onSuccess={onSuccess} />
    }

    return (
        <div className={classes.wrapper} onClick={() => errorMessage && setError('')}>
            {renderHeader()}
            <form className={classes.container} noValidate>
                {renderError()}
                {renderTitle()}
                {renderStep()}
            </form>
        </div>
    );
}

export default ResetPass;
