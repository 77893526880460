import './App.css';
import LoginWidget from './LoginWidget'
import SignupWidget from './SignupWidget'
import PageNotFound from './PageNotFound'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import dataStorage from './dataStorage';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

const getTheme = () => {
  switch (dataStorage.theme) {
    case 'light':
      return {
        spacing: (factor) => `${0.4 * factor}rem`,
        palette: {
          mode: 'light',
          background: {
            secondary: dataStorage.palette?.secondary || '#EFF1F8',
            primary: dataStorage.palette?.primary || '#FFFFFF',
            paper: dataStorage.palette?.primary || '#FFFFFF'
          },
          primary: {
            main: dataStorage.palette?.highlight || '#03a9f4'
          },
          error: {
            main: dataStorage.palette?.error || '#C05463'
          },
          text: {
            primary: dataStorage.palette?.color || 'rgba(0, 0, 0, 0.87)',
            secondary: dataStorage.palette?.color || 'rgba(0, 0, 0, 0.6)',
            disabled: dataStorage.palette?.color || 'rgba(0, 0, 0, 0.38)'
          },
          textFieldBorder: {
            main: 'rgba(0, 0, 0, 0.23)',
            hover: 'rgba(0, 0, 0, 0.87)'
          },
          another: {
            main: dataStorage.palette?.another || dataStorage.palette?.highlight || '#03a9f4'
          }
        }
      }
    default: return {
      spacing: (factor) => `${0.4 * factor}rem`,
      palette: {
        mode: 'dark',
        background: {
          secondary: dataStorage.palette?.secondary || '#262B3E',
          primary: dataStorage.palette?.primary || '#171B29',
          paper: dataStorage.palette?.primary || '#171B29'
        },
        primary: {
          main: dataStorage.palette?.highlight || '#039be5'
        },
        error: {
          main: dataStorage.palette?.error || '#C05463'
        },
        text: {
          primary: dataStorage.palette?.color || '#ffffff',
          secondary: dataStorage.palette?.color || '#ffffff',
          disabled: dataStorage.palette?.color || '#ffffff3b'
        },
        textFieldBorder: {
          main: '#ffffff3b',
          hover: '#ffffff',
        },
        another: {
          main: dataStorage.palette?.another || dataStorage.palette?.highlight || '#039be5'
        }
      }
    }
  }
}

function App() {

  const obj = getTheme()
  if (dataStorage.font) {
    obj.typography = {
      fontFamily: dataStorage.font
    }
  }

  if (dataStorage.fontSize) {
    if (obj.typography) obj.typography.fontSize = +dataStorage.fontSize
    else {
      obj.typography = {
        fontSize: +dataStorage.fontSize
      }
    }
  }

  let btnDisabledObj = {}
  if (dataStorage.palette?.disabled) {
    btnDisabledObj.background = dataStorage.palette?.disabled
  }

  if (dataStorage.palette?.textDisabled) {
    btnDisabledObj.color = dataStorage.palette?.textDisabled
  }

  let theme = createTheme(obj);
  theme = createTheme(theme, {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitTextFillColor: theme.palette.text.primary,
              caretColor: theme.palette.text.primary
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'unset'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            background: theme.palette.background.secondary,
            '& svg': {
              fill: theme.palette.text.primary
            }
          }
        }
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            position: 'absolute'
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // padding: theme.spacing(1.5),
            textTransform: 'unset',
            color: theme.palette.text.primary,
            '&.Mui-disabled': btnDisabledObj
          }
        }
      }
    }
  })
  theme = responsiveFontSizes(theme)

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={dataStorage.pageNotFound ? <PageNotFound /> : <LoginWidget />}></Route>
            <Route path="/signup" element={dataStorage.pageNotFound ? <PageNotFound /> : <SignupWidget />}></Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
