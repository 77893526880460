import { BRAND } from './constants'
import dataStorage from './dataStorage'

const ERROR_MAPPING = {
    OK: {
        message: '(#20001) Application Submitted.',
        description: 'Successful'
    },
    INVALID_ARGUMENT: {
        message: '(#10032) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Client specified an invalid argument. Check error message and error details for more information.'
    },
    FAILED_PRECONDITION: {
        message: '(#10001) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Request can not be executed in the current system state, such as deleting a non-empty directory.'
    },
    OUT_OF_RANGE: {
        message: '(#10002) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Client specified an invalid range.'
    },
    UNAUTHENTICATED: {
        message: '(#10003) Your session is expired. Please refresh the screen and continue with your application.',
        description: 'Request not authenticated due to missing, invalid, or expired OAuth token.'
    },
    PERMISSION_DENIED: {
        message: '(#10004) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Client does not have sufficient permission. This can happen because the OAuth token does not have the right scopes, the client doesn\'t have permission, or the API has not been enabled.'
    },
    NOT_FOUND: {
        message: '(#10005) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'A specified resource is not found.'
    },
    ABORTED: {
        message: '(#10006) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Concurrency conflict, such as read-modify-write conflict.'
    },
    ALREADY_EXISTS: {
        message: '(#10007) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'The resource that a client tried to create already exists.'
    },
    RESOURCE_EXHAUSTED: {
        message: '(#10008) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Either out of resource quota or reaching rate limiting.'
    },
    CANCELLED: {
        message: '(#10009) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Request cancelled by the client.'
    },
    DATA_LOSS: {
        message: '(#10010) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Unrecoverable data loss or data corruption. The client should report the error to the user.'
    },
    UNKNOWN: {
        message: '(#10011) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Unknown server error. Typically a server bug.'
    },
    INTERNAL: {
        message: '(#10012) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Internal server error. Typically a server bug.'
    },
    NOT_IMPLEMENTED: {
        message: '(#10013) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'API method not implemented by the server.'
    },
    'N/A': {
        message: '(#10014) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Network error occurred before reaching the server. Typically a network outage or misconfiguration.'
    },
    UNAVAILABLE: {
        message: '(#10015) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Service unavailable. Typically the server is down.'
    },
    DEADLINE_EXCEEDED: {
        message: '(#10016) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Request deadline exceeded. This will happen only if the caller sets a deadline that is shorter than the method\'s default deadline (i.e. requested deadline is not enough for the server to process the request) and the request did not finish within the deadline.'
    },
    UPDATE_FAILED: {
        message: 'Failed to update'
    },
    2003: {
        message: 'Your user login must use 3-255 characters and cannot contain spaces.'
    },
    2006: {
        message: 'Invalid permission'
    },
    2008: {
        message: 'Invalid user status. Please try again.'
    },
    2009: {
        message: 'This account is taken. Try another'
    },
    2032: {
        message: 'Your username has been temporarily blocked for security reason. Please contact Equix Support.'
    },
    2062: {
        message: 'Incorrect Password or User Login. Please make sure your details are correct and try again'
    },
    2061: {
        message: 'Please wait a minute and reset password again'
    },
    2063: {
        message: 'Invalid vetting rules group ID. Please try another one.'
    },
    2089: {
        message: 'Your PIN has been expired. Please relogin and change your PIN'
    },
    2010: {
        message: 'Your password must include 8-25 characters and contain at least one lowercase, one capital letter and one number.'
    },
    2011: {
        message: 'Incorrect Password'
    },
    2015: {
        message: 'Invalid status. Please try another one.'
    },
    2077: {
        message: 'Verification code expired.'
    },
    2000: {
        message: 'Sorry, an unknown error has occurred. Please contact EQUIX Support.'
    },
    2090: {
        message: 'Password is required'
    },
    2004: {
        message: 'Invalid email address. Please try again'
    },
    2002: {
        message: 'This user login is already taken. Please try another one.'
    },
    2078: {
        message: 'Incorrect verification code. Please retry again'
    },
    2080: {
        message: 'New password needs to be different from three latest ones'
    },
    3001: {
        message: 'It looks like you are accessing the wrong environment. Questions or confusions? Email hello@equix.app, and a team member of us will be happy to help.'
    },
    8000: {
        message: 'Captcha verification failed. Please try again.'
    },
    2091: {
        message: 'Email template doesn\'t exist'
    },
    2092: {
        message: 'ACCOUNT ID/ ADVISOR CODE/ BRANCH CODE/ ORGANISATION CODE doesn\'t exist'
    },
    2095: {
        message: 'For access to add-on, please contact Equix Support.'
    },
    2099: {
        message: 'Roles group is not existing'
    },
    2053: {
        message: 'Branch name must not exceed 255 characters.'
    },
    2027: {
        message: 'This role ID is not existed. Please try another one.'
    },
    2028: {
        message: 'x'
    },
    2026: {
        message: 'There is no change in the role information'
    },
    4000: {
        message: 'Only one account is mapped for a retail client'
    },
    2025: {
        message: 'This role is not existed. Please try another one.'
    },
    2024: {
        message: 'This role is existed. Please try another one.'
    },
    2035: {
        message: 'This user group ID cannot be removed.'
    },
    2056: {
        message: 'This vetting rules group ID cannot delete.'
    },
    2055: {
        message: 'This branch cannot be removed as there is user ID in this branch.'
    },
    2054: {
        message: 'Invalid branch name. Please try another one.'
    },
    2052: {
        message: 'Invalid branch name. Please try another one.'
    },
    2050: {
        message: 'This branch name is already existed. Please try another one.'
    },
    2012: {
        message: 'Username is invalid'
    },
    2005: {
        message: 'Invalid phone number format. Please enter the phone in the form of (xxx) xxx-xxx-xxx'
    },
    2014: {
        message: 'Invalid account ID. Please try another one.'
    },
    2096: {
        message: 'You are not able to create this role.'
    },
    2098: {
        message: 'You are not able to create this role.'
    },
    2097: {
        message: 'User group does not exist.'
    },
    2001: {
        message: 'Full name is invalid'
    },
    4001: {
        message: 'Invalid API Access. Please try another one.'
    },
    2094: {
        message: 'Live news is invalid'
    },
    2086: {
        message: 'Notes must be less than 1,000 characters'
    },
    PARAM_IS_NULL: {
        message: 'Invalid parameter'
    },
    PARAM_INVALID: {
        message: 'Invalid parameter'
    },
    VALIDATE_FAILED: {
        message: 'Invalid input'
    },
    NOTHING_CHANGED: {
        message: 'Nothing has been changed'
    },
    INSERT_FAILED: {
        message: 'Failed to insert'
    },
    QUERY_INVALID: {
        message: 'Invalid query'
    },
    GET_FAILED: {
        message: 'Failed to get information'
    },
    NOT_EXIST: {
        message: 'Invalid information'
    },
    DATA_EXIST: {
        message: 'Data exist'
    },
    UNKNOWN_ERROR: {
        message: 'Unknown error'
    },
    INVALID_MAPPING: {
        message: 'Invalid mapping'
    },
    INVALID_PARAMS: {
        message: 'Invalid parameter'
    },
    ACCOUNT_LOCKED: {
        message: 'Saxo account is locked'
    },
    ACCOUNT_NOT_EXIST: {
        message: 'Account is not supported to trade international stock'
    },
    OTP_EXPIRED: {
        message: '(#10018) This email verification code is expired. Please request a new code and try again.'
    },
    UNAUTHORIZED: {
        message: 'UNAUTHORIZED'
    },
    INVALID_OTP: {
        message: '(#10021) Incorrect email verification code. Please try again.'
    },
    USED_OTP: {
        message: '(#10020) This email verification code has been used. Please request a new code and try again.'
    },
    OTP_UNAVAILABLE: {
        message: '(#10017) This email already has Advisor access. Please use a different email for onboarding service.'
    },
    INVALID_ENVIRONMENT: {
        message: '(#10022) Unfortunately, we cannot process your request right now. Please try again.'
    },
    INVALID_PASSWORD: {
        message: '(#10023) Incorrect email or password. Please try gain.'
    },
    ALREADY_APPROVED_KYC: {
        message: '(#10024) This application KYC has been already approved.'
    },
    ALREADY_REJECTED: {
        message: '(#10025) This application has been already rejected.'
    },
    INVALID_APPLICANT: {
        message: '(#10027) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    INVALID_APPLICATION: {
        message: '(#10028) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    INVALID_APPLICATION_STATUS: {
        message: '(#10029) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    INVALID_KYC_STATUS: {
        message: '(#10030) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    ALREADY_APPROVED_BANK: {
        message: '(#10026) This application Bank Info has been already approved.'
    },
    INVALID_TOKEN: {
        message: '(#10031) Incorrect email verification code. Please try again.'
    },
    MISSING_BENEFICIARY: {
        message: '(#10033) The Application should have at least one beneficiary.'
    },
    MISSING_BENEFICIAL_OWNER: {
        message: '(#10034) The Application should have at least one beneficial owner.'
    },
    USER_ALREADY_EXISTS: {
        message: '(#10035) This email is already used by another account.'
    },
    INVALID_USER: {
        message: 'Invalid user'
    }
}

export default ERROR_MAPPING
//2067 2051 BRANCH_ID_IS_DEFAULT
export function getErrorMessage(error) {
    let errorCode = error?.response?.data?.errorCode || error?.response?.data?.error_code
    if (Array.isArray(errorCode)) errorCode = errorCode[0]
    if (error?.message === 'Network Error') return ''
    if (errorCode === 2000) {
        return `Sorry, an unknown error has occurred. Please contact ${BRAND[dataStorage.whitelabel]} Support.`
    }
    return (errorCode && ERROR_MAPPING[errorCode] && ERROR_MAPPING[errorCode].message) || error?.message || ''
}