/* eslint-disable no-constant-condition */
import React, { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MuiTextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles'
const useStyle = makeStyles((theme) => {
  return {
    container: {
      background: 'transparent !important',
      '& > div': {
        background: theme.palette.background.secondary
      }
    }
  }
})

const TextField = (props) => {
  const { errorText, onChange, disableTrim, ...rest } = props;
  const classes = useStyle();
  const [value, setValue] = useState(props.value || '');
  const isSmallScreen = useMediaQuery('(max-width:376px)');

  const onChangeText = (e) => {
    const value = e.target.value || ''
    setValue(disableTrim ? value : value.trim());
    onChange(disableTrim ? value : value.trim());
  }

  const resetText = () => {
    setValue('')
    onChange('')
  }

  const renderIcon = () => {
    return value ?
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          onClick={resetText}
        >
          <CloseIcon />
        </IconButton>
      </InputAdornment>
      : null
  }

  const size = isSmallScreen ? 'small' : 'medium'
  return (
    <MuiTextField
      type={'text'}
      size={size}
      onChange={onChangeText}
      {...rest}
      value={value}
      className={classes.container}
      inputProps={{
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off'
        }
      }}
      InputProps={{
        endAdornment: renderIcon()
      }} />
  )
}

export default TextField
